export function callFunction(func, ...args) {
  func.length ? this[func](...args) : false;
}
export const documentTypes = () => [
  // value - как в журнале
  // detailPage - uri на детальной странице документа
  // namespace - префикс в сторе
  // service - название микросервиса
  {
    name: "СД",
    value: "sd",
    detailPage:"statistics",
    namespace:"statistics",
    fileSuffix:"sd",
    service:"sd"
  },
  {
    name: "ТД",
    value: "td",
    detailPage:"transit",
    namespace:"transit",
    fileSuffix:"td",
    service:"td"
  },
  {
    name: "СО",
    value: "so",
    detailPage:"ensuring",
    namespace:"ensuring",
    fileSuffix:null,
    service:"so"
  },
  {
    name: "РС",
    value: "rs",
    detailPage:"rs",
    namespace:"rs",
    fileSuffix:"dt",
    service: "us"
  },
  {
    name: "ЭПИ",
    value: "epi",
    detailPage:"epi",
    namespace:"epi",
    fileSuffix:"piat",
    service: "epi"
  },
  {
    name: "ПТД",
    value: "ptd",
    detailPage:"passenger",
    namespace:"ptd",
    fileSuffix:null,
    service:"ptd"
  },
  {
    name: "CЭЗ",
    value: "sez",
    detailPage:"sez",
    namespace:"sez",
    fileSuffix:"r1",
    service:"sez"
  },
  {
    name: "ДТ",
    value: "dt",
    detailPage:"dt",
    namespace:"dt",
    fileSuffix:null,
    service:"dt"
  },
  {
    name: "УВР",
    value: "uvr",
    detailPage:"uvr",
    namespace:"uvr",
    fileSuffix:null,
    service:"uvr"
  },
  {
    name: "ПРОПУСК",
    value: "pass",
    detailPage:null,
    namespace:"pass",
    fileSuffix:null,
    service:"pass" // uvr фактически
  },
  {
    name: "ДТC",
    value: "dts",
    detailPage:"dts",
    namespace:"dts",
    fileSuffix:null,
    service:"dts"
  },
  {
    name: "ЗВТ",
    value: "zvt",
    detailPage:"zvt",
    namespace:"zvt",
    fileSuffix:null,
    service:"zvt"
  },
];

export function getJournalDocTypeByService(value){
  const service = value.toString().toLowerCase()
  return documentTypes().find(i => i.service === service).value ?? null
}
export function getNamespaceByService(value){
  const service = value.toString().toLowerCase()
  return documentTypes().find(i => i.service === service).namespace ?? null
}
export function getDetailPageByService(value){
  const service = value.toString().toLowerCase()
  return documentTypes().find(i => i.service === service).detailPage ?? null
}
export function getStoreNamespaceByJournalDoc(value){
  const module = value.toString().toLowerCase()
  return documentTypes().find(i => i.value === module).namespace ?? null
}


export function getJournalDocTypeByUrl(value){
  const module = value.toString().toLowerCase()
  return documentTypes().find(i => i.detailPage === module).value ?? null
}

export function getStoreDocTypeByUrl(value){
  const module = value.toString().toLowerCase()
  return documentTypes().find(i => i.detailPage === module).namespace ?? null
}