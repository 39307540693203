import axios from "axios";
import {DT, CATALOGS, TNVED} from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";
import wareDetailsActionsFactory from "@/store/utils/ware-details-actions";
import paymentOrders from "@/store/dt-payment-orders";

const documentActions = documentActionsFactory(DT);
const wareDetailsActions = wareDetailsActionsFactory(DT);

export default {
  namespaced: true,
  state: {
    selected: {},
    selectedWares: [],
    editableWare: {id: null, index: null},
    requests: [],
    aeos: [],
    nsiTransportMarks: [],
    control: {},
    extraTnvedOptions: {
      firstOptions: [],
      secondOptions: [],
      thirdOptions: [],
      fourthOptions: []
    },
    paymentTypes: []
  },
  mutations: {
    ...documentMutations,
    SET_EXTRA_TNVED_OPTIONS(state, preparedOptions) {
      state.extraTnvedOptions = preparedOptions
    },
    SET_NSI_AEOS(state, aeos) {
      state.aeos = aeos;
    },
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    ADD_REQUEST(state, promise) {
      state.requests.push(promise);
    },
    REMOVE_REQUEST(state, promise = null) {
      if (promise) {
        const idx = state.requests.indexOf(promise);
        state.requests.splice(idx, 1);
      } else {
        state.requests = [];
      }
    },
    UPDATE_DECLARATION_PAYMENT(state, items) {
      if (items.length === state.selected.declaration_payments.length) {
        state.selected.declaration_payments.splice(
          0,
          state.selected.declaration_payments.length,
          ...items
        );
      } else {
        items.forEach((item) => {
          const {id} = item;
          const current = state.selected.declaration_payments.find(
            (i) => i.id === id
          );
          const index = state.selected.declaration_payments.indexOf(current);
          state.selected.declaration_payments.splice(index, 1, item);
        });
      }
    },
    UPDATE_DECLARATION_PAYMENTS(state, ids) {
      const new_arr = state.selected.declaration_payments.filter((i) => !ids.includes(i.id))
      state.selected.declaration_payments = new_arr;
    },
    ADD_PAYMENTS_ITEM(state, item) {
      state.selected.declaration_payments.push(item);
    },
    SET_NSI_TRANSPORT_MARKS(state, nsiTransportMarks) {
      state.nsiTransportMarks = nsiTransportMarks;
    },
    RESET_GOODS_AND_DOCS(state, payload) {
      state.selectedWares = payload.wares;
      if (payload.presented_documents) {
        state.selected.presented_documents = payload.presented_documents;
      }
    },
    SET_PAYMENT_TYPES(state, payload) {
      state.paymentTypes = payload.list.map((i) => {
        return {
          ...i,
          search: `${i.code}-${i.name}`
        }
      })
    },
    SET_CLIENT_DIVISION_ID(state, clientDivisionId){
      state.selected.client_division_id = clientDivisionId
    }
  },
  actions: {
    ...documentActions,
    ...wareDetailsActions,
    fetchNsiAeos({commit}) {
      axios.get(`${CATALOGS}nsiAeos`).then((res) => {
        commit("SET_NSI_AEOS", res.data.list);
      });
    },
    updateDeclarationPayment({commit}, {payload}) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${DT}declarationPayment/updateAll`, data)
        .then((res) => {
          commit("UPDATE_DECLARATION_PAYMENT", data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deletePaymentItem({commit, state}, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      axios
        .post(
          `${DT}declarationPayment/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then(() => {
          commit("UPDATE_DECLARATION_PAYMENTS", data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    createNewPayment({commit, state}) {
      axios
        .post(`${DT}declarationPayment/create?declarationId=${state.selectedId}`)
        .then((res) => {
          commit("ADD_PAYMENTS_ITEM", res.data);
        });
    },
    copyDocument({rootState}, {id, copyDocuments, wareIds, copyAllWares}) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${DT}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&copyDocuments=${copyDocuments}` +
        `&userId=${uid}` +
        `&copyAllWares=${copyAllWares}`
      ;
      return axios.post(url, wareIds);
    },
    deleteDocumentArr({commit, state}, selected) {
      const declarationId = state.selected.id;
      return axios
        .post(`${DT}presentedDocument/deleteByIds?declarationId=${declarationId}`,
          [...selected]
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    fetchNsiTransportMarks({commit}) {
      return axios.get(`${CATALOGS}nsiTransportMarks`).then((res) => {
        const result =
          res.data?.list.map((i) => {
            return {
              ...i,
              text: [i.code, i.name].join("-"),
              search: [i.code, i.name].join("-").toLowerCase(),
            };
          }) || [];
        commit("SET_NSI_TRANSPORT_MARKS", result);
      });
    },
    // Заявка
    attachWorksAct(_, formData) {
      return axios.post(`${DT}order/attachWorksAct`, formData);
    },
    getDocumentArchive(_, id) {
      return axios
        .get(`${DT}order/downloadDocuments?declarationId=${id}`, {
          responseType: "blob",
        })
    },
    getPdf(_, id) {
      return axios.get(`${DT}order/downloadPdf?declarationId=${id}`, {
        responseType: "blob",
      });
    },
    addDocuments(_, formData) {
      return axios.post(`${DT}order/attachDocuments`, formData);
    },
    sendToEclient(_, {xml, declarationId, userId, divisionId, ptoNumber}) {
      return axios.post(
        `${DT}declaration/sendToEclient?userId=${userId}&divisionId=${divisionId}&declarationId=${declarationId}&ptoId=${ptoNumber}`,
        xml,
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
    },
    uploadExcel(
      {dispatch, state},
      {formData, id, ...params}
    ) {
      let url = `${DT}declaration/uploadXlsx?declarationId=${id}`
      Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
      })
      return axios
        .post(url, formData)
        .then((res) => {
          dispatch('getDocumentById', state.selected.id)
          return res;
        });
    },
    // TODO: вынести в модуль ТНВЭД
    getAntiDumpingsByTnved(_, {tn_ved, date}) {
      return axios.get(
        `${TNVED}api/read/getAntidumpingsByTnVd?tnVed=${tn_ved}&declarationDate=${date}`
      )
    },
    getAvailableExcise(_, payload) {
      if (!payload.tn_ved) Promise.reject(new Error("Отсутствует код ТНВЭД"));
      return axios
        .get(`${TNVED}api/read/getExcisesByTnVed?tnVed=${payload.tn_ved}&declarationDate=${payload.date}`)
        .then(({data}) => ({data, type: 2}));
    },
    sortGoods({state, commit}, {sortBy = "id"}) {
      const id = state.selected.id;
      if (!id) return;
      axios
        .post(`${DT}ware/sortWares?declarationId=${id}&sortBy=${sortBy}`)
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
        })
    },
    deleteGoodsAmount({commit, state}, {ids}) {
      return axios.post(`${DT}ware/deleteByIds?declarationId=${state.selected.id}`, ids).then((res) => {
        commit("RESET_GOODS_AND_DOCS", res.data);
        return res;
      });
    },
    deleteAllGoods({commit, state}) {
      return axios
        .post(`${DT}ware/deleteByIds?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("RESET_GOODS_AND_DOCS", res.data);
          return res;
        });
    },
    addExtraTnvedRules({commit}, {declarationId, rules}) {
      return axios.post(`${DT}ware/applyAddTnVedRule?declarationId=${declarationId}`, rules)
        .then((res) => {
          commit('REPLACE_BLOCK_IN_DECLARATION', {
            wares: res.data
          })
        })
    },
    divideCost({state}) {
      const id = state.selected.id;
      return axios.post(`${DT}declaration/calculateIncludingCosts?declarationId=${id}`)
    },
    fillNetWeightNoPackFromNetWeight({commit}, declarationId) {
      return axios.post(`${DT}ware/fillNetWeightNoPackFromNetWeight?declarationId=${declarationId}`)
        .then((res) => commit("RESET_GOODS", res.data))
    },
    calcWeightWithParam({commit}, {id, weight, field}) {
      return axios.post(`${DT}ware/calculateWeight?declarationId=${id}&weight=${weight}&fromField=${field}`)
        .then((res) => commit("RESET_GOODS", res.data))
    },
    calcPackageWeight({commit}, {id, weight}) {
      return axios
        .post(`${DT}ware/recalculateGrossWeightByAddingPackage?declarationId=${id}&commonGrossWeight=${weight}`
        ).then((res) => commit("RESET_GOODS", res.data));
    },
    clearPayments({state, commit}) {
      const id = state.selected.id;
      return axios.post(`${DT}declaration/clearAllPayments?declarationId=${id}`)
        .then((res) => commit('SET_SELECTED', res.data))
    },
    recalculateInvoiceCostAllWares({state, commit}) {
      const id = state.selected.id;
      return axios.post(`${DT}declaration/recalculateInvoiceCost?declarationId=${id}`)
        .then((res) => {
          commit('SET_SELECTED', res.data)
          return res
        })
    },
    fillExciseUnitQuantityWithNetWeight({state, commit}) {
      const id = state.selected.id;
      return axios.post(`${DT}ware/fillExciseUnitQuantityWithNetWeight?declarationId=${id}`)
        .then((res) => commit("UPDATE_GOODS_ITEM_IN_DECLARATION", res.data));
    },
    getCustomsDuties(_, {tn_ved, date}) {
      return axios.get(`${TNVED}api/read/getCustomsDutiesByTnVed?tnVed=${tn_ved}&declarationDate=${date}`)
    },
    createDTS({state, rootState}, dtsN) {
      const declarationId = state.selected.id;
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${DT}declaration/createDts?declarationId=${declarationId}&userId=${userId}&dtsN=${dtsN}`)
    },
    createSD({state, rootState}) {
      const declarationId = state.selected.id;
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${DT}declaration/createSd?declarationId=${declarationId}&userId=${userId}`)
    },
    getByAddMeasureUnitLetter(_, {declarationId, addMeasureUnitLetter}) {
      return axios.get(`${DT}ware/getByAddMeasureUnitLetter?declarationId=${declarationId}&addMeasureUnitLetter=${addMeasureUnitLetter}`)
    },
    calculateAddMeasureUnitQuantityFromWareDetails(_, {declarationId, payload}) {
      return axios.post(`${DT}ware/calculateAddMeasureUnitQuantityFromWareDetails?declarationId=${declarationId}`, payload)
    },
    setClientDivisionId({state}, divisionId) {
      const declarationId = state.selected.id;
      let url = `${DT}declaration/setClientDivisionId?declarationId=${declarationId}`
      if (divisionId) url += `&divisionId=${divisionId}`
      return axios.post(url)
    },
    ...paymentOrders
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getNsiAeos: (s) => s.aeos,
    getRequests: (s) => s.requests,
    getEditableWare: (s) => s.editableWare,
    getNsiTransportMarks: (s) => s.nsiTransportMarks,
    getControl: (s) => s.control,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getExtraTnvedOptions: (s) => s.extraTnvedOptions,
    getPaymentTypes: (s) => s.paymentTypes,
    isImport40: (s) => {
      const {customs_procedure, declaration_kind_code} = s.selected?.type_declaration ?? {}
      return declaration_kind_code === 'ИМ' && customs_procedure === "40"
    },
    isImport: (s) => {
      const {declaration_kind_code} = s.selected?.type_declaration ?? {}
      return declaration_kind_code === 'ИМ'
    },
    isExport: (s) => {
      const {declaration_kind_code} = s.selected?.type_declaration ?? {}
      return declaration_kind_code === 'ЭК'
    }
  },
};
